<template>
    <vx-card class="overflow-hidden mb-6 my-balance-card" v-if="data">
        <div slot="no-body">
            <div class="p-6 flex justify-between flex-row-reverse items-center">
                <feather-icon icon="DollarSignIcon" class="p-3 inline-flex rounded-full"></feather-icon>
                <div :class="balanceClass" class="balance-sum">
                    {{ data.current_balance | currency }}
                </div>
                <div class="truncate">
                    <h2 class="mb-1 font-bold">Mittagspause Kasse</h2>
                    <span>Mein aktueller Kontostand <u class="pseudo-link" @click="openLastTransactionPopup" >Letzte Transaktionen anzeigen</u></span>
                </div>
            </div>

            <employee-lunchbreak-last-transactions-popup :active="isLastTransactionModalActive" v-if="data"
                                                         @close="closeLastTransactionModal" :transactions="data.last_transactions"></employee-lunchbreak-last-transactions-popup>

        </div>
    </vx-card>
</template>
<script>
import ApiService from "@/api";
import EmployeeLunchbreakLastTransactionsPopup from "@/components/employee/EmployeeLunchbreakLastTransactionsPopup.vue";
import EmployeeLunchbreakOverviewPopup from "@/components/employee/EmployeeLunchbreakOverviewPopup.vue";

export default {
    name: "MyLunchbreakBalanceCard",
    components: {EmployeeLunchbreakOverviewPopup, EmployeeLunchbreakLastTransactionsPopup},
    data() {
        return {
            data: null,
            isLastTransactionModalActive: false,
        }
    },
    computed: {
        balanceClass() {
            return {
                'text-danger': this.data.current_balance < 0,
                'text-success': this.data.current_balance >= 0,
            };
        },
    },
    created() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            ApiService.get('lunchbreak-cash-desk/my-balance')
                .then(({data}) => {
                    this.data = data.result;
                })
                .catch(({response}) => {
                    this.$vs.notify({
                        title: 'Fehler',
                        text: response.data.message,
                        color: 'danger',
                        position: 'top-right'
                    })
                })
        },
        formatDateTime(date) {
            return moment(date).format('DD.MM.YYYY HH:mm:ss');
        },
        closeLastTransactionModal() {
            this.isLastTransactionModalActive = false;

        },
        openLastTransactionPopup() {
            this.isLastTransactionModalActive = true;

        },
    },

}


</script>
<style lang="scss" scoped>

.my-balance-card {
  background-color: #242424;

  .balance-sum {
    padding-top: 10px;
    font-size: 2.3rem;
    font-weight: 600;
  }

  .truncate {
    min-width: 78%;

    h2 {
      color: #fff;
    }

    span {
      color: #bbbaba;
    }
  }
}
</style>