<template>
    <vs-prompt
            title="Aktueller Stand in der Mittagpausen Kasse"
            @close="$emit('close')"
            @cancel="$emit('close')"
            @accept="$emit('close')"
            cancelText="Schließen"
            acceptText="Schließen"
            class="employee-lunchbreak-last-transactions-popup"
            :active.sync="activePrompt">

        <div class="bg-gray p-2">
            <vs-row>
                <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="6">
                    Vermerk
                </vs-col>
                <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="2">
                    Betrag
                </vs-col>
                <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="2">
                    Datum
                </vs-col>
                <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="2">
                    Kumulierter Wert
                </vs-col>
            </vs-row>
        </div>

        <div v-for="(transaction, index) in transactions">

            <vs-row v-if="transaction.note && transaction.sum" class="p-2">
                <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="6">
                    <span>{{ transaction.note }}</span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="2">
                    <b>{{ transaction.sum | currency }}</b>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="2">
                    {{ formatDateTime(transaction.created_at) }}
                </vs-col>
                <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="2">
                    <i>{{ transaction.cumulative_balance | currency }}</i>
                </vs-col>
            </vs-row>
        </div>

    </vs-prompt>
</template>

<script>
import ApiService from "../../api";
import moment from 'moment';
import {mapGetters} from "vuex";
import * as qs from "qs";

export default {
    name: "EmployeeLunchbreakLastTransactionsPopup",
    components: {moment},
    props: {
        active:{
            type: Boolean,
        },
        transactions: {
            type: Array|null,
        }

    },
    data() {
        return {
            activePrompt: false,
            employees: [],
        }
    },
    computed: {
        ...mapGetters(['userId', 'workspace', 'userHasPermission']),
    },
    created() {

    },
    methods: {

        formatDateTime(value) {
            if (!value) return '-'
            return moment(new Date(value)).format('DD.MM.YYYY')
        },
        activeChange(value) {
            this.activePrompt = value;
        },

    },
    watch: {
        active: 'activeChange'
    }
}
</script>

<style lang="scss">
.employee-lunchbreak-last-transactions-popup .vs-dialog {
    max-width: 710px;
}
.bg-gray {
    background-color: #e0e0e0;
}
</style>