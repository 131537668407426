<template>
  <div>
    <vs-popup :active="activeModal" @close="onClose" title="Bearbeiten oder hinzufügen">
      <form>
        <div class="mb-5">
          <label for="titleInput"><b>Titel:</b></label><br>
          <input v-model="item.title" type="text"  class="w-full vs-input--input normal">
        </div>
        <div class="mb-5">
          <label><b>Kategorie:</b></label><br>
          <category-selector v-model="item.categories" type="internal_news"></category-selector>
        </div>
        <label for="titleInput"><b>Inhalt:</b></label><br>
        <vue-editor class="mb-5" v-model="item.description" name="short_description" :editorToolbar="toolbar"
                    data-vv-as="Beschreibung"/>
        <vs-button class="mr-3" type="filled" size="small" @click="saveData(item)">Speichern</vs-button>
        <vs-button type="border" size="small" color="warning" @click="$emit('deleteData', item)">Löschen</vs-button>
      </form>
    </vs-popup>
  </div>
</template>

<script>
import ApiService from "../../api";
import * as qs from "qs";
import CategorySelector from "@/components/search/CategorySelector";

export default {
  name: "addInternalNewsModal",
  components: {CategorySelector},
  props: {
    activeModal: {
      type: Boolean
    },
    data: {
      type: Array
    },
    item: {
      type: Object
    }
  },
  data() {
    return {
      toolbar: [
        ['bold', 'italic', 'underline', 'image'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'color': []}],
      ],
    };
  },
  created() {
  },
  computed: {
    visible() {
      return this.activeModal;
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    saveData(item) {
      this.$emit('saveFromChild', item);
    },
  }
}
</script>

<style scoped>
input, textarea {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.mb-5 {
  margin-bottom: 5px;
}

.mr-3 {
  margin-right: 3px;
}

</style>