<template>
  <div class="internal-news-board">
    <vs-row vs-justify="center">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <div class="custom-card">
          <div class="news-header">
            <div class="relative padding-without-bottom-10">
              <h2 class="mb-big font-bold text-center">Neuigkeiten</h2>
              <vs-button type="filled" class="absolute" @click="addNews()" v-if="userHasPermission('create_edit_internal_news')">Neu</vs-button>
            </div>
            <div class="divider"></div>
            <div class="flex padding-sides-10">
              <div  class="filter-select">
                <v-select
                    class="shadow"
                    v-model="selectedCategories"
                    :options="categories"
                    placeholder="Kategorien filtern"
                    :searchable="true"
                    multiple
                    clearable
                    autowidth
                    label="name">
                </v-select>
              </div>
            </div>
          </div>
          <div class="news-container">
            <div v-for="(item, index) in filteredCategories" class="" :key="item.id">
              <internal-news-board-item
                  :item="item"
                  :index="index"
                  @readMore="readMore"
                  @editNews="editNews"
                  @addIcon="addIcon">
              </internal-news-board-item>
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>
    <internal-news-modal
        :activeModal.sync="AddPopUpActive"
        :data="data"
        :item="newsData"
        @close="closeInternalNewsModal"
        @saveFromChild="saveData"
        @deleteData="deleteData">
    </internal-news-modal>
    <read-more-modal
        :activeModal.sync="MorePopUpActive"
        :item="currentItem"
        @close="closeInternalNewsModal">
    </read-more-modal>
  </div>
</template>

<script>
import ApiService from "../../api";
import * as qs from "qs";
import InternalNewsModal from "./InternalNewsModal.vue"
import ReadMoreModal from "./ReadMoreModal.vue"
import InternalNewsBoardItem from "./InternalNewsBoardItem.vue"
import vSelect from "vue-select";
import CategorySelector from "@/components/search/CategorySelector";
import {mapGetters} from "vuex";

export default {
  name: "InternalNewsBoard",
  data() {
    return {
      data: [],
      AddPopUpActive: false,
      MorePopUpActive: false,
      categories: [],
      currentItem: {},
      selectedCategories: [],
      filteredEvents: [],
      newsData: {
        id: null,
        title: '',
        description: '',
        categories: []
      },
    }
  },
  components: {
    InternalNewsBoardItem,
    InternalNewsModal,
    ReadMoreModal,
    vSelect,
    CategorySelector

  },
  created() {
    this.fetchData();
    this.categoriesPromise()
  },
  computed: {
    ...mapGetters(['userHasPermission', 'userId', 'user']),
    filteredCategories() {
      const filterCategories = this.selectedCategories.map(category => category.id, []);
      if (filterCategories && filterCategories.length > 0) {
        return this.data.filter(item => {
          return item.categories.find(category => filterCategories.includes(category.id)) !== undefined;
        });
      }
      return this.data
    },
  },
  methods: {
    categoriesPromise() {
      ApiService.get('categories', {
        params: {
          filter: [{
            field: 'usable_on',
            value: 'internal_news'
          }],
        },
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
            this.categories = response.data.result
          }
      );
    },
    fetchData() {
      ApiService.get('internal-news?order[field]=created_at&order[direction]=desc', {
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.data = response.data.result;
      }).catch(response => {
      })
    },
    closeInternalNewsModal() {
      this.AddPopUpActive = false
      this.MorePopUpActive = false
      this.fetchData()
    },
    editNews(item) {
      this.newsData.id = item.id
      this.newsData.title = item.title
      this.newsData.description = item.description
      this.newsData.categories = item.categories
      this.AddPopUpActive = true
    },
    addNews() {
      this.newsData.id = null
      this.newsData.title = ''
      this.newsData.description = ''
      this.AddPopUpActive = true
    },
    readMore(item) {
      this.currentItem = item
      this.MorePopUpActive = true
    },
    saveData(item) {
      item.creator_user_id = this.userId;
      if (item.id != null) {
        ApiService.put(`internal-news/${item.id}`, item)
            .then(response => {
              if (response.data.status === "success") {
                this.closeInternalNewsModal()
                this.$vs.notify({
                  title: 'Erfolgreich',
                  text: 'Eintrag bearbeitet',
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'success'
                })
              }
            }).catch((response) => {
          this.$vs.notify({
            title: 'Fehler',
            text: response.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        });
      } else {
        ApiService.post('internal-news', item)
            .then(response => {
              if (response.data.status === "success") {
                this.closeInternalNewsModal()
                this.$vs.notify({
                  title: 'Erfolgreich',
                  text: 'Neuer Eintrag hinzugefügt',
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'success'
                })
              }
            }).catch((response) => {
          this.$vs.notify({
            title: 'Fehler',
            text: response.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        });
      }
    },
    deleteData(item) {
      ApiService.delete('internal-news/' + item.id)
          .then(response => {
            if (response.data.status === "success") {
              this.AddPopUpActive = false
              this.fetchData()
              this.$vs.notify({
                title: 'Erfolgreich',
                text: 'Eintrag gelöscht',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
              })
            }
          })
          .catch((response) => {
            this.$vs.notify({
              title: 'Fehler',
              text: response.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          });
    },
    addIcon(icon, index, item) {
      ApiService.post('voting', {type: icon, internal_news_id: item.id})
          .then(response => {
            this.fetchData()
          }).catch((response) => {
      });
    },
  },
}
</script>

<style scoped>
.relative {
  position: relative;
}

.absolute {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}

.filter-title {
  font-size: 14px;
}

input {
  border: 1px solid rgba(0, 0, 0, 0.1);
}


.mb-big {
  margin-bottom: 1.5rem;
}

.divider {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
}

.title {
  font-size: 21.14px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: #2c2c2c;
  margin-bottom: 8px;
}

.custom-card {
  background-color: #FFFFFF;
  border-radius: 5px;
  margin-bottom: 15px;
  -webkit-box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
}

.padding-without-bottom-10 {
  padding: 10px 10px 0 10px;
}

.padding-sides-10 {
  padding: 10px;
}

.news-header {
  box-shadow: 0 4px 2px -2px rgb(0 0 0 / 10%);
  z-index: 10;
}

.news-container {
  overflow: auto;
  max-height: 400px;
}

.filter-select {
  width: 100%;
  max-width: 300px;
}
</style>