<template>
    <div id="dashboard-analytics">
        <div class="vx-row">

            <!-- CARD 1: CONGRATS -->
            <div class="vx-col w-full lg:w-1/2 mb-base " >

              <PresentUsers />

            </div>

            <div class="vx-col w-full lg:w-1/2 mb-base" v-if="employees">
                <vx-card slot="no-body">
                    <h3>Rückruf erwünscht</h3>
                    <p>Teile deinem Kollegen eine Rückrufbitte mit. </p>

                    <vs-divider/>
                    <vs-row>
                        <vs-col vs-type="flex" vs-align="center" vs-w="6">
                            <h6 class="mb-4">Gewünschter Gesprächspartner:</h6>
                        </vs-col>
                        <vs-col vs-type="flex" vs-align="center" vs-w="6">
                            <h6 class="mb-4">Dringlichkeit:</h6><br>
                        </vs-col>
                    </vs-row>
                    <vs-row>
                        <vs-col vs-type="flex" vs-align="center" vs-w="6">
                            <v-select :options="employees" class="w-full mr-4"
                                      v-model="recall_data.employee"></v-select>
                        </vs-col>
                        <vs-col vs-type="flex" vs-align="center" vs-w="6">
                            <v-select :options="urgencyOptions" class="w-full" v-model="recall_data.urgency"></v-select>
                        </vs-col>
                    </vs-row>
                    <vs-divider/>

                    <h6 class="mb-0">Anrufer-Informationen:</h6>
                    <vs-row>
                        <vs-col vs-type="flex" vs-align="center" vs-w="4">
                            <vs-input class="w-full mr-4" icon-pack="feather" icon="icon-user" icon-no-border
                                      label-placeholder="Name des Anrufers" v-model="recall_data.contact"/>
                        </vs-col>
                        <vs-col vs-type="flex" vs-align="center" vs-w="4">
                            <vs-input class="w-full mr-4" icon-pack="feather" icon="icon-user" icon-no-border
                                      label-placeholder="Firma des Anrufers" v-model="recall_data.company"/>
                        </vs-col>
                        <vs-col vs-type="flex" vs-align="center" vs-w="4">
                            <vs-input class="w-full" icon-pack="feather" icon="icon-smartphone" icon-no-border
                                      label-placeholder="Tel. Nr. oder E-Mail" v-model="recall_data.contact_details"/>
                        </vs-col>
                    </vs-row>

                    <br>
                    <div class="vx-row mb-2">
                        <div class="vx-col w-full">
                            <vs-textarea label="Das Anliegen strichwortartig zusammengefasst ..."
                                         v-model="recall_data.message"/>
                        </div>
                    </div>

                    <ul class="demo-alignment">
                        <li>
                            <label>wünscht Rückruf</label>
                            <vs-switch color="success" v-model="recall_data.option1" icon-pack="feather"
                                       vs-icon="icon-check"/>
                        </li>
                        <li>
                            <label>ruft nochmal an</label>
                            <vs-switch color="success" v-model="recall_data.option2" icon-pack="feather"
                                       vs-icon="icon-check"/>
                        </li>
                        <li>
                            <label>probiert es auf dem Handy</label>
                            <vs-switch color="success" v-model="recall_data.option3" icon-pack="feather"
                                       vs-icon="icon-check"/>
                        </li>
                    </ul>

                    <br>

                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <vs-button class="mr-3 mb-2">Submit</vs-button>
                            <vs-button color="warning" type="border" class="mb-2"
                                       @click="input21 = input22 = input23 = input24 = ''; check6 = false;">Reset
                            </vs-button>
                        </div>
                    </div>
                </vx-card>
            </div>


        </div>

        <div v-if="userHasPermission('show_power_dashboard') && powerDashboardCachedAt" class="cashed_at_time">
          Letzte Aktualisierung: {{ formatDateTime(powerDashboardCachedAt) }}
          <span class="link ml-3" @click="cacheClear">Cache leeren</span>
        </div>
        <div class="vx-row mb-8" v-if="userHasPermission('show_power_dashboard')">

            <div class="vx-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
                <statistics-card-line
                    icon="DollarSignIcon"
                    icon-right
                    :statistic="formatCurrency(powerDashboard.turnover.value)"
                    statisticTitle="Umsatz (dieses Jahr)"
                    :titleTooltipText="powerDashboard.turnover.comparison_last_year_percent + '% vom letzten Jahr. ' + percentageOfTheYearPast + '% ist dieses Jahr bereits vorbei. Unterschied: ' + powerDashboard.turnover.comparison_last_year_sum"
                    :chartData="powerDashboard.turnover.series"
                    type="area"/>
            </div>
            <div class="vx-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
                <statistics-card-line
                    v-if="workspace.id === 1"
                    icon="UserCheckIcon"
                    icon-right
                    statistic="<todo>"
                    statisticTitle="Performance letzte 30 Tage"
                    :chartData="siteTraffic.series"
                    color="warning"
                />

                <!--- only workspace 2 -->
                <statistics-card-line
                    v-if="workspace.id === 2"
                    icon="UserCheckIcon"
                    icon-right
                    :statistic="powerDashboard.sold_b2b_licenses.value"
                    statisticTitle="Verkaufte Lizenzen (dieses Jahr)"
                    titleTooltipText="Min. 20 Lizenzen pro Jahr - Erwartung 30!"
                    :chartData="powerDashboard.sold_b2b_licenses.series"
                    color="warning"
                     />
            </div>

            <div class="vx-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4" v-if="workspace.id === 1">
                <statistics-card-line
                    icon="DollarSignIcon"
                    icon-right
                    statistic="<todo>"
                    statisticTitle="Erwarteter Umsatz (3 Folgemonate)"
                    :chartData="siteTraffic.series"
                    color="danger" />
            </div>

            <div class="vx-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
                <statistics-card-line
                    icon="DollarSignIcon"
                    icon-right
                    :statistic="formatCurrency(powerDashboard.arr_next_year.value)"
                    statisticTitle="Planbare Umsätze für Folgejahr (ARR)"
                    :chartData="powerDashboard.arr_next_year.series"
                    color="danger"
                    type="area"
                />
            </div>


            <div class="vx-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4" v-if="workspace.id === 2">
                <statistics-card-line
                    icon="UserCheckIcon"
                    icon-right
                    :chartData="[]"
                    statisticTitle="Aktive Partneranzahl"
                    :statistic="powerDashboard.active_partners.value"
                    color="warning"
                    type="area"
                />
                </div>
        </div>

        <div class="vx-row mb-base">
            <div class="vx-col w-full md:w-2/4 lg:w-2/4 xl:w-2/4" v-if="powerDashboard.sold_b2b_licenses.licenseList.length > 0">

               <h2>Lizenznehmer (komplett): ({{powerDashboard.sold_b2b_licenses.licenseList.length}})</h2>
                <ul>
                    <li v-for="(licensee, i) in powerDashboard.sold_b2b_licenses.licenseList" >
                        {{i+1}} ) {{ licensee.company_name }}  | <small>Rechnung # {{ licensee.invoice_number }} vom {{licensee.order_date}}</small>
                    </li>
                </ul>
            </div>
        </div>

        <!-- ROW 2 -->
        <div class="vx-row mb-base">

            <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                <present-user-bonus-quickview :user="user" :allow-change-mode="true"></present-user-bonus-quickview>
                <accounting-execution-card></accounting-execution-card>
            </div>
            <div class="vx-col w-full md:w-2/3 lg:w-2/3 xl:w-2/3">
                <my-lunchbreak-balance-card></my-lunchbreak-balance-card>
                <internal-news-board></internal-news-board>
            </div>
        </div>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'
    import StatisticsCardLine from '../components/statistics-cards/StatisticsCardLine.vue'
    import vSelect from 'vue-select'
    import ApiService from "../api";
    import * as qs from "qs";
    import {mapGetters} from "vuex";
    import AccountingExecutionCard from '../components/accounting-execution-card/AccountingExecutionCard.vue'
    import InternalNewsBoard from '../components/internal-news-board/InternalNewsBoard.vue'
    import PresentUsers from "@/views/dashboard/PresentUsers.vue";
    import PresentUserBonusQuickview from "@/views/dashboard/PresentUserBonusQuickview";
    import PriceHelper from "@/mixins/helper/price.helper";
    import MyLunchbreakBalanceCard from "@/components/statistics-cards/MyLunchbreakBalanceCard.vue";
    import moment from "moment/moment";


    export default {
        components: {
            MyLunchbreakBalanceCard,
          PresentUserBonusQuickview,
          PresentUsers,
            StatisticsCardLine,
            'v-select': vSelect,
            AccountingExecutionCard,
            InternalNewsBoard
        },
        data() {
            return {

                selectedYear: null,
                selectedQuarter: null,

                // Line Charts

                siteTraffic: {
                    series: [{
                        name: '2022',
                        data: [70, 100, 125, 150, 200, 250],
                        total: 544984.7999999999
                    },
                    {
                        name: 'Traffic Rate',
                        data: [150, 200, 125, 225, 200, 250],
                        total: 544984.7999999999
                    }
                    ],
                },
                powerDashboardCachedAt: null,
                powerDashboard: {
                    turnover: {
                        comparison_last_year_percent: 0,
                        comparison_last_year_sum: 0,
                        value: 0,
                        series: [],
                    },
                    arr_next_year: {
                        value: 0,
                        series: [],
                    },
                    sold_b2b_licenses: {
                        value: 0,
                        series: [],
                        licenseList: []
                    },
                    active_partners: {
                        value: 0
                    },
                },
                employeesSelected: {countryCode: "AUS", countryName: 'Tim Resl'},
                users: [],
                recall_data: {
                    urgency: {value: '1', label: 'Niedrig'},
                    employee: null,
                    contact: '',
                    contact_details: '',
                    company: '',
                    message: '',
                    option1: false,
                    option2: false,
                    option3: false
                },
                urgencyOptions: [
                    {value: '1', label: 'Niedrig'},
                    {value: '2', label: 'Hoch'},
                    {value: '3', label: 'Mittel'}
                ]
            }
        },
        created() {
            this.fetchUsers()
            this.fetchPowerDashboard()
        },
        methods: {
            fetchUsers() {
                let queryParams = {

                };

                this.$vs.loading()
                ApiService.get('users',{
                    params: queryParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})}).then(response => {
                    this.users = response.data.result;
                    this.$vs.loading.close()
                }).catch(response => {
                })
            },
            fetchPowerDashboard() {
                let queryParams = {

                };

                this.$vs.loading()
                ApiService.get('controlling/power-dashboard',{
                    params: queryParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})}).then(response => {
                    this.powerDashboardCachedAt = response.data.cached_at;
                    this.powerDashboard.turnover.series = response.data.result.turnover;
                    this.powerDashboard.turnover.value =response.data.result.turnover_this_year;
                    this.powerDashboard.turnover.comparison_last_year_percent = (response.data.result.turnover[1]['total'] / response.data.result.turnover[0]['total'] * 100).toFixed(0) ;
                     var missingPercent = this.powerDashboard.turnover.comparison_last_year_percent - this.percentageOfTheYearPast
                    this.powerDashboard.turnover.comparison_last_year_sum = this.formatCurrency((missingPercent * response.data.result.turnover[1]['total'] / 100)) ;

                    this.powerDashboard.arr_next_year.series = response.data.result.arr_next_year;
                    this.powerDashboard.arr_next_year.value = response.data.result.arr_next_year_total;

                    this.powerDashboard.sold_b2b_licenses.series = [response.data.result.sold_b2b_licenses_this_year];
                    this.powerDashboard.sold_b2b_licenses.value = response.data.result.sold_b2b_licenses_this_year.total;
                    this.powerDashboard.sold_b2b_licenses.licenseList = response.data.result.sold_b2b_licenses_owner;

                    this.powerDashboard.active_partners.value = response.data.result.active_partners;

                    this.$vs.loading.close()
                }).catch(response => {
                })
            },
            formatCurrency(number) {
                return PriceHelper.formatCurrency(number);
            },
          cacheClear() {
            ApiService.post('controlling/cache-clear').then(response => {
              this.fetchPowerDashboard()
            }).catch(response => {
            })
          },
          formatDateTime(value) {
            return moment(new Date(value)).format('DD.MM.YYYY - H:m')
          },
        },
        computed: {
            ...mapGetters(['userHasPermission','user','workspace']),
            employees() {
                let employeeArray = [];
                if(this.users.length >= 1) {
                    this.users.forEach((user) => {
                        employeeArray.push({
                            value: user.id,
                            label: user.detail.first_name + ' ' + user.detail.last_name
                        })
                    });
                }

                //this.recall_data.employee = employeeArray[0];

                return employeeArray;
            },
            percentageOfTheYearPast() {
                const currentDate = new Date();

                const year = currentDate.getFullYear();
                const currentDayOfYear = (currentDate - new Date(year, 0, 0)) / 86400000; // 86400000 milliseconds per day
                const percentageOfYearPassed = (currentDayOfYear / 365) * 100;

                return percentageOfYearPassed.toFixed(0);
            },
            missedTurnover() {
                this.powerDashboard.turnover.comparison_last_year
                return this.powerDashboard.turnover.value - this.powerDashboard.turnover.series[1]['total'];
            },
        }
    }
</script>


<style lang="scss">

    #dashboard-analytics {
        .greet-user {
            position: relative;
            .decore-left {
                position: absolute;
                left: 0;
                top: 0;
            }
            .decore-right {
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        @media(max-width: 576px) {
            .decore-left, .decore-right {
                width: 140px;
            }
        }
    }

    .overlay-card {
        font-size: 80px;
        text-align: center;
        color: #d52b2b;
        font-weight: 600;
        padding-top: 120px;
        text-shadow: 1px 1px 42px #ffffff;
        font-style: italic;
    }
    .welcome-img {
        background-repeat: no-repeat;
        margin-left: 25px!important;
        border-radius: 5px;
        height: 100%;
    }
    .cashed_at_time {
        text-align: right;
        font-size: 12px;
        color: #9e9e9e;
        margin-bottom: 10px;
    }
</style>
