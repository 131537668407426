<template>
    <div>
        <vs-row vs-justify="center">
            <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
                <vs-card>
                    <h2 class="mb-3 font-bold text-center">Ist der Abrechnungs Cronjob gelaufen?</h2>
                    <div class="flex justify-center">
                        <feather-icon :icon="icon" class="p-3 rounded-full feather-icon select-none large-icon"
                                      :class="[`text-${color}`]"
                                      :style="{background: `rgba(var(--vs-${color}),.15)`}"></feather-icon>
                        <div class="text-center ml-8 custom-card-title font-bold">
                            <p>Letzes gespeichertes Datum:</p>
                            <span v-for="entry in arrayReverse.slice(0, 1)">{{formatDate(entry.execution_date)}}</span>
                        </div>
                    </div>
                    <div>
                        <vs-list>
                            <vs-list-item v-for="entry in arrayReverse.slice(1,7)" icon="remove" :key="entry.id"
                                          :title="formatDate(entry.execution_date)"  class="justify-center">
                            </vs-list-item>
                        </vs-list>
                    </div>
                </vs-card>
            </vs-col>
        </vs-row>
    </div>

</template>

<script>
    import moment from 'moment';
    import ApiService from "../../api";
    import * as qs from "qs";

    export default {
        name: "AccountingExecutionCard",
        data() {
            return {
                dateExists: false,
                icon: '',
                color: '',
                accountingExeLogs: [],
            }
        },
        created() {
            this.getAccountingExecutionLog()
        },
        props: {
        },
        computed: {
            arrayReverse() {
                return this.accountingExeLogs.reverse()
            },
        },
        methods: {
            checkIfYesterdayExist() {
                var array = this.accountingExeLogs;
                var yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
                for (var i = 0; i < array.length; i++) {
                    if (yesterday === array[i].execution_date) {
                        this.dateExists = true
                    }
                }
                if (this.dateExists === true) {
                    this.icon = 'CheckCircleIcon'
                    this.color = 'success'
                } else {
                    this.icon = 'AlertCircleIcon'
                    this.color = 'danger'
                }
            },
            getAccountingExecutionLog() {
                ApiService.get('accounting_execution_logs',{
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})}).then(response => {
                    this.accountingExeLogs = response.data.result;
                }).then(response => {
                    this.checkIfYesterdayExist()
                }).catch(response => {
                })

            },
            formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            }
        }
    }
</script>

<style scoped>
    .custom-card-title {
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.01rem;
    }
    .vs-list--header, .vs-list--item {
        justify-content: center !important;
    }
</style>