<template>
  <div
      class="grid grid-cols-9 p-3 rounded select-none border shadow cursor-pointer"
      :class="{'border-success': !isPaused,'border-primary': isPaused}"
      @click.self="createTimeSheetEntry"
      id="dashboard-stamp-terminal-loading"
  >

    <div class="col-span-1 flex items-center"   @click="createTimeSheetEntry">
      <gravatar :email="user.email" class="rounded-full shadow-md block w-full" />
    </div>

    <div class="col-span-8 flex column items-center pl-4 justify-between" @click.self="createTimeSheetEntry">
      <div   @click="createTimeSheetEntry">
        <h3
            class="h4"
            :class="{'text-success': !isPaused,'text-primary': isPaused}"
        >
          {{ user.name }}
        </h3>

        <div
            v-if="timeSheet"
            class="text-xs"
            :class="{'text-success': !isPaused,'text-primary': isPaused}"
        >

          <div>
            {{ currentMinutes | hours }} <span class="font-normal">Std.</span>
          </div>

          <template v-if="getDateForToday.entries">
            <template v-for="(entry, index) in getDateForToday.entries">
              <span>{{ entry.timestamp | time }}</span>
              <span v-if="(index + 1) < getDateForToday.entries.length"> - </span>
            </template>
          </template>

        </div>

        <span
            v-else
            class="text-xs"
            :class="{'text-success': !isPaused,'text-primary': isPaused}"
        >
          Kein Arbeitszeitbreicht verfügbar.
        </span>

      </div>



      <div class="flex column items-end">

        <div class="flex">
          <vs-icon
              icon-pack="material-icons"
              icon="apartment"
              size="small"
              title="Büro Esslingen"
              @click="applyActivityLocation('apartment')"
              :class="{'text-success': isLocation('apartment'),'text-primary': !isLocation('apartment')}"
          />
          <vs-icon
              icon-pack="material-icons"
              icon="home"
              size="small"
              title="Homeoffice"
              @click="applyActivityLocation('home')"
              :class="{'text-success': isLocation('home'),'text-primary': !isLocation('home')}"
          />
        </div>

        <vs-icon
            icon-pack="material-icons"
            icon="schedule"
            size="medium"
            :class="{'text-success': !isPaused,'text-primary': isPaused}"
            @click.self="createTimeSheetEntry"
        />
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Gravatar from 'vue-gravatar';
import moment from "moment";
import cloneDeep from "lodash/cloneDeep.js";
import ApiService from "@/api/index.js";

export default {
  name: "PresentUserCurrentUser",
  components: {
    Gravatar
  },

  data() {
    return {
      timeSheet: null,
      userActivityLocation: null,
      currentMinutes: 0,
    }
  },

  computed: {
    ...mapGetters(['user', 'userId']),

    isPaused() {
      if (!this.getDateForToday) {
        return true;
      }

      const dateEntry = this.getDateForToday;

      if (!dateEntry.entries) {
        return true;
      }

      return (dateEntry.entries.length % 2) === 0;
    },
    getDateForToday() {
      if (!this.timeSheet) {
        return null;
      }

      const result = this.timeSheet.date_entries[moment().format('YYYY-MM-DD')];

      if (result) {
        return cloneDeep(result)
      }

      return null;
    },
  },

  created() {
    this.fetchData();

  },

  mounted() {
    setInterval(() => {
      this.calculateTimer();
    }, 15000);
  },

  methods: {
    fetchData() {
      const year = moment().format('YYYY');
      const month = moment().format('MM');

      ApiService.get(`time-sheet` +
          `?filter[user_id]=${this.userId}` +
          `&filter[year]=${year}` +
          `&filter[month]=${month}` +
          `&limit=1` +
          `&includes=entries` +
          `&appends=date_entries`
      ).then(response => {
        this.fetchUserActivityLocation();
        this.stopLoader();
        if (response.data.result) {
          this.timeSheet = response.data.result[0];
          this.calculateTimer();
        }
      });
    },

    fetchUserActivityLocation() {
      const date = moment().format('YYYY-MM-DD');

      ApiService.get(`time-sheet-user-location/${this.userId}/${date}`
      ).then(response => {

        if (response.data.result) {
          this.userActivityLocation = response.data.result;
        } else {
          this.userActivityLocation = null;
        }
      });
    },

    isLocation(location) {
      if (this.userActivityLocation == null){
        return false;
      }
      if (this.userActivityLocation.activity_location == location){
        return true;
      }
      return false;
    },

    applyActivityLocation(location){

      this.openLoader();

      const data = {
        user_id: this.timeSheet.user_id,
        date: moment().format('YYYY-MM-DD'),
        activity_location: location
      }

      ApiService.post('time-sheet-user-location', data).then(() => {
        this.fetchData();
      }).catch(error => {
        error.handleUnkown = true;
        error.handleGlobally();
      });


    },

    createTimeSheetEntry() {
      this.openLoader();

      const data = {
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
        time_sheet_id: this.timeSheet.id
      }

      ApiService.post('time-sheet-entry', data).then(() => {
        this.fetchData();
      }).catch(error => {
        error.handleUnkown = true;
        error.handleGlobally();
      });
    },

    openLoader() {
      this.$vs.loading({
        container: '#dashboard-stamp-terminal-loading',
        scale: 0.6
      })
    },

    stopLoader() {
      this.$vs.loading.close('#dashboard-stamp-terminal-loading > .con-vs-loading')
    },

    calculateTimer() {
      const currentDate = cloneDeep(this.getDateForToday);

      if (!currentDate) {
        this.currentMinutes = 0;
        return;
      }

      const trackedMinutes = parseFloat(currentDate.hours) * 60;

      if (this.isPaused) {
        this.currentMinutes = trackedMinutes;
        return;
      }

      if(!this.getDateForToday.entries || this.getDateForToday.entries.length < 1) {
        this.currentMinutes = trackedMinutes;
        return;
      }

      const lastEntry = this.getDateForToday.entries[this.getDateForToday.entries.length - 1];
      const diff = moment().diff(moment(lastEntry.timestamp));
      const duration = moment.duration(diff).asMinutes();

      this.currentMinutes = trackedMinutes + duration;
    },

  },

  filters: {
    time(date) {
      return moment(date).format('HH:mm');
    },

    hours(time) {
      let hours = (time / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);

      rhours = ("0" + rhours).slice(-2);
      rminutes = ("0" + rminutes).slice(-2);

      return rhours + ":" + rminutes;
    }
  }
}
</script>

<style scoped>

</style>