<template>
  <div>
    <div class="padding-20">
      <div class="flex justify-between">
        <h3 class="title">{{ item.title }}</h3>
        <span>{{ item.created_at.slice(0, 10) }}</span>
      </div>
      <div v-html="item.description" class="description">{{ item.description }}</div>
    </div>
    <div class="news-item-bottom">
      <div class="icon-container">
          <span v-for="(votes, key) in displayIcon" class="position-relative single-icon-container"
                v-bind:class="isVoteIconActive(votes)">

            <span class="single-icon pointer" @click="$emit('addIcon', key, index, item);">
                <feather-icon class="vote-icon" :icon="key"
                              svgClasses="w-4 h-4"/>
                <span>{{ votes.length }}</span>
            </span>
            <span class="position-absolute tooltip display-none">
                <span class="" v-for="vote in votes">
                  {{ vote.user.detail.first_name + ', ' }}
                </span>
              <span class="icon-text">{{ 'reacted with: ' + key }}</span>
            </span>
         </span>
        <span class="position-relative flex" v-click-outside="onClickOutsideEvent">
          <feather-icon class="pointer" icon="PlusIcon" svgClasses="w-4 h-4"
                        @click="IconChoiceActive = !IconChoiceActive"/>
          <span class="absolute mt-4" v-show="IconChoiceActive">
                  <span class="flex justify-between voting-choice">
                      <feather-icon @click="$emit('addIcon', 'SmileIcon', index, item); IconChoiceActive = false"
                                    class="icon" icon="SmileIcon" svgClasses="w-4 h-4"/>
                      <feather-icon @click="$emit('addIcon', 'FrownIcon', index, item); IconChoiceActive = false"
                                    class="icon" icon="FrownIcon" svgClasses="w-4 h-4"/>
                      <feather-icon @click="$emit('addIcon', 'ThumbsUpIcon', index, item); IconChoiceActive = false"
                                    class="icon" icon="ThumbsUpIcon" svgClasses="w-4 h-4"/>
                      <feather-icon @click="$emit('addIcon', 'ThumbsDownIcon', index, item); IconChoiceActive = false"
                                    class="icon" icon="ThumbsDownIcon" svgClasses="w-4 h-4"/>
                      <feather-icon @click="$emit('addIcon', 'CheckIcon', index, item); IconChoiceActive = false"
                                    class="icon" icon="CheckIcon" svgClasses="w-4 h-4"/>
                      <feather-icon @click="$emit('addIcon', 'XIcon', index, item); IconChoiceActive = false"
                                    class="icon" icon="XIcon" svgClasses="w-4 h-4"/>
            </span>
          </span>
        </span>
      </div>
      <div class="buttons-container">
        <vs-button class="mr-3" type="filled" size="small" @click="$emit('readMore', item)">weiterlesen</vs-button>
        <vs-button class="border-button" type="border" size="small" color="warning" @click="$emit('editNews', item)"
                   v-if="userHasPermission('create_edit_internal_news')">bearbeiten
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import groupBy from "lodash/groupBy";
import {mapGetters} from "vuex";

export default {
  name: "InternalNewsBoardItem",
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {}
  },
  data() {
    return {
      IconChoiceActive: false,
    }
  },
  computed: {
    displayIcon() {
      return groupBy(this.item.voting, 'type')
    },
    ...mapGetters(['userHasPermission', 'userId', 'user']),
  },
  methods: {
    isVoteIconActive(icon) {
      var loggedInuserId = this.userId
      for (var i = 0; i < icon.length; i++) {
        if (icon[i].user_id == loggedInuserId) {
          return "active";
        }
      }
      return false;
    },
    onClickOutsideEvent() {
      this.IconChoiceActive = false
    },
  },
  directives: {
    'click-outside': {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    }
  },

}
</script>

<style scoped>

.d-flex {
  display: flex;
}

.mr-3 {
  margin-right: 3px;
}

.padding-20 {
  padding: 20px;
  padding-bottom: 0;
}

.divider {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 15px 0;
  width: 100%;
}

.title {
  font-size: 21.14px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: #2c2c2c;
  margin-bottom: 8px;
}

.description {
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  height: 60px;
  /* width: auto; */
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
}

.news-container {
  overflow: auto;
  max-height: 400px;
}

.voting-choice {
  padding: 3px 6px;
  max-width: 150px;
  color: black;
  background-color: white;
  border-radius: 10px;
  border: 1px solid red;
  box-shadow: 0 4px 2px -2px rgb(0 0 0 / 10%);
  position: absolute;
}

.icon-container {
  position: relative;
  display: flex;
  align-items: center;
}

.vote-icon {
  margin-right: 5px;
}

.news-item-bottom {
  padding: 10px 20px;
  /*
    border-top: 1px solid #939393;
  */
  box-shadow: 0 6px 5px 0 rgb(0 0 0 / 10%);
  display: flex;
  justify-content: space-between;
}

.voting-choice {
  left: 15px;
  top: -4px;
}


@media screen and (max-width: 950px) {
  /*  .voting-choice {
      right: 210px;
      bottom: 0;
    }*/
  .news-item-bottom {
    display: block;
  }

  .buttons-container {
    padding-top: 5px;
  }
}

.voting-choice .icon {
  cursor: pointer;
  padding: 0 3px;
}

.buttons-container {
  display: inline-flex;
  position: relative;

}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.display-none {
  display: none;
}

.tooltip {
  z-index: 100;
  bottom: 150%;
  left: 0;
  background-color: #0f163a;
  color: white;
  padding: 3px;
  min-width: 200px;
  border-radius: 4px;
}

.icon-text {
  color: rgba(255, 255, 255, 0.5);
}

.single-icon:hover + .display-none {
  display: block;
}

.single-icon {
  padding: 0 5px;
  background-color: #F7F7F7;
  margin: 0 3px;
  border-radius: 80px 80px 80px 80px / 75px 75px 75px 75px;
  border: 1px solid #F7F7F7;
  display: flex;
}

.pointer {
  cursor: pointer;
}

.internal-news-board .news-container .single-icon-container.active .single-icon {
  background: rgba(52, 165, 213, 0.2);
  border: 1px solid #34A5D5;
}
</style>