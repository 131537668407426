<template>
  <div>
    <vs-popup :active="activeModal" @close="onClose" title="Mehr lesen">
      <h3 class="mb-5">{{ item.title }}</h3>
      <div v-html="item.description">{{ item.description }}</div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  name: "ReadMoreModal",
  props: {
    activeModal: {
      type: Boolean,
    },
    item: {
      type: Object
    },
    index: {
      type: Number
    }
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    logData(item) {
      console.log(item)
    }
  },
  created() {
  }
}

</script>

<style scoped>
.mb-5 {
  margin-bottom: 5px;
}
</style>