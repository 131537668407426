<template>
  <vx-card class="h-full" ref="card">

    <div class="grid grid-cols-1 lg:grid-cols-2 col-gap-4 row-gap-4">

      <div>
        <h3>Deine Kollegen</h3>
        <p>Sehe, wer gerade am Arbeiten ist.</p>
      </div>

      <PresentUserCurrentUser />

    </div>

    <vs-divider/>

    <div class="grid grid-cols-1 lg:grid-cols-2 col-gap-4 row-gap-4">

      <div
          v-for="(user, index) in users"
          :key="index"
          class="grid grid-cols-9 p-3 rounded select-none border shadow"
          :class="{'border-success': user.status === 0, 'border-dark': user.status === 1, 'border-yellow-500': user.status === 2, 'border-primary': user.status === 3}"
          :title="user.absence_name"
      >

        <div class="col-span-1 flex items-center">

          <gravatar :email="user.email" class="rounded-full shadow-md block w-full" />

        </div>

        <div class="col-span-8 flex column items-center pl-4 justify-between">
          <div>
            <h3
                class="h4"
                :class="{'text-success': user.status === 0, 'text-dark': user.status === 1, 'text-yellow-500': user.status === 2, 'text-primary': user.status === 3}"
            >
              {{ user.name }}
            </h3>

            <span
                v-if="user.time_sheet_entries.length > 0"
                class="text-xs"
                :class="{'text-success': user.status === 0, 'text-dark': user.status === 1, 'text-yellow-500': user.status === 2, 'text-primary': user.status === 3}"
            >{{ getTimes(user) }}</span>
          </div>

          <div
              class="flex column items-center"
              :class="{'text-success': user.status === 0, 'text-dark': user.status === 1, 'text-yellow-500': user.status === 2, 'text-primary': user.status === 3}"
          >
            <vs-icon
                icon-pack="material-icons"
                icon="home"
                size="small"
                title="Homeoffice"
                v-if="user.time_sheet_user_activity_location === 'home'"
            />
            <vs-icon
                icon-pack="material-icons"
                icon="apartment"
                size="small"
                title="Büro Esslingen"
                v-if="user.time_sheet_user_activity_location === 'apartment'"
            />
            <vs-icon
                icon-pack="material-icons"
                icon="how_to_reg"
                size="medium"
                v-if="user.status === 0"
            />
            <vs-icon
                icon-pack="material-icons"
                icon="close"
                size="medium"
                v-else-if="user.status === 1"
            />
            <vs-icon
                icon-pack="material-icons"
                icon="hotel"
                size="medium"
                v-else-if="user.status === 3"
            />
            <vs-icon
                icon-pack="material-icons"
                icon="wb_sunny"
                size="medium"
                v-else
            />
          </div>
        </div>

      </div>

    </div>

  </vx-card>
</template>

<script>
import ApiService from "@/api/index.js";
import Gravatar from 'vue-gravatar';
import moment from "moment";
import PresentUserCurrentUser from "@/views/dashboard/PresentUserCurrentUser.vue";

export default {
  name: "PresentUsers",
  components: {
    PresentUserCurrentUser,
    Gravatar
  },
  data() {
    return {
      users: [],
      dataFetched: false,
    }
  },
  methods: {
    getTimes(user) {

      return user.time_sheet_entries.map((entry) => {
        return moment(entry.timestamp).format('HH:mm');
      }).join(' - ');

    },

    loadUsers() {

      this.$refs.card.refreshcard();

      ApiService.get('/dashboard-present-users')
          .then((res) => {
            this.users = res.data.result;
            this.dataFetched = true;

            this.$refs.card.removeRefreshAnimation();
          });
    }
  },
  mounted() {
    this.loadUsers();
  }
}
</script>

<style scoped>

</style>